import _ from 'lodash'
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const numCol = 3

function CCTVs({ nowTime, cctv_camera }) {
	const imgCCTV = []
	const [data, setData] = useState([])
	const [refreshTime, setRefreshTime] = useState(nowTime)

	useEffect(() => {
		setData(cctv_camera)
	}, [cctv_camera])

	useEffect(() => {
		setRefreshTime(nowTime)
	}, [nowTime])

	if (data.length === 0) {
		return imgCCTV
	}

	let rowCCTV = []
	_.map(data, (item, idx) => {
		const { title, imgurl, rotation } = item

		let urlQueryConnect = '?'
		if (imgurl.includes('?')) urlQueryConnect = '&'

		rowCCTV.push(
			<div className='column' key={`${title}-${idx}`}>
				<figure className='image is-3by2'>
					<LazyLoadImage
						alt={title}
						placeholderSrc={title}
						// wrapperProps={{
						// 	// If you need to, you can tweak the effect transition using the wrapper style.
						// 	style: {transform: 'rotate(180deg)'},
						// }}
						// src={imgurl}
						src={`${imgurl}${urlQueryConnect}refresh=${refreshTime}`}
						effect='blur'
						// style={{transform: `rotate(${rotation}deg)`}}
					/>
				</figure>
				<h2 style={{fontSize:14}} className='subtitle'>{title}</h2>
			</div>
		)

		if (rowCCTV.length === numCol || idx === data.length - 1) {
			imgCCTV.push(
				<div key={`Row${imgCCTV.length}`} className='columns'>
					{rowCCTV}
				</div>
			)

			rowCCTV = []
		}
	})

	return imgCCTV
}

export default CCTVs
