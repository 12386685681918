import './App.css'

import _ from 'lodash'
import { useEffect, useState } from 'react'
import cctv_exp65 from './cctv/expressway65.json'
import cctv_provicial1 from './cctv/provicial1.json'
import CCTVsP1 from './components/CCTVsP1'
import CCTVs65 from './components/CCTVs65'

const refreshTime = 10 * 1000

function App() {
	const [nowTime, setNowTime] = useState(Date.now())

	useEffect(() => {
		const timeInterval = setInterval(() => {
			setNowTime(Date.now())
		}, refreshTime)

		return () => {
			clearInterval(timeInterval)
		}
	}, [])

	return (
		<div className='container'>
			<nav className='level'>
				<div className='level-item'>
					<h1 className='title'>自己上班，自己看路！</h1>
				</div>
			</nav>
			<CCTVsP1 nowTime={nowTime} cctv_camera={cctv_provicial1} />
			{/* <CCTVs65 nowTime={nowTime} cctv_camera={cctv_exp65}/> */}
		</div>
	)
}

export default App
